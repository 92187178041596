import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { isSupportedBrowser } from '@app/browser-utils';
import { convertDateToReadableFormat } from '@app/date-utils';
import { MatDialog } from '@angular/material/dialog';
import { LauncherInstructionsDialogComponent } from '@app/launcher-instructions-dialog/launcher-instructions-dialog.component';
import { ExtensionStateService } from '@app/extension-state/extension-state.service';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import * as sha256 from 'fast-sha256';
import { environment as env } from '@env/environment';
import { getDefaultDialogConfig } from '@app/dialog-utils';
import { MountService } from '@app/services/mount.service';

@Component({
  selector: 'app-launcher-install',
  templateUrl: './launcher-install.component.html',
  styleUrls: ['./launcher-install.component.scss'],
})
export class LauncherInstallComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public launcherVersion: string | undefined | null = null;
  public lastRefresh: Date | undefined;
  public isExtensionInstalledResult: boolean | undefined;
  public productGuideLink: string = `https://www.agilicus.com/product-guide/launcher-install-desktop/`;
  private refreshButtonState: string = 'default';

  public getProductLink(): string {
    const email = this.authService.auth.email();
    if (!email) {
      return this.productGuideLink;
    }
    const te = new TextEncoder();
    const hash = sha256.hmac(te.encode(env.tawk_key), te.encode(email));
    const hashHex = Array.prototype.map.call(hash, (x) => x.toString(16).padStart(2, '0')).join('');
    return this.productGuideLink + `?email=${encodeURIComponent(email)}&tawk_hash=${encodeURIComponent(hashHex)}`;
  }

  constructor(
    private authService: AuthService,
    private launcherInstructionsDialog: MatDialog,
    private extensionStateService: ExtensionStateService,
    private mountService: MountService
  ) {}

  public ngOnInit(): void {
    const isExtensionInstalled$ = this.extensionStateService.getExtensionInstalledStatus();
    const launcherInfo$ = this.extensionStateService.getLauncherInfo();
    combineLatest([isExtensionInstalled$, launcherInfo$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isExtensionInstalledResult, launcherInfoResult]) => {
        this.isExtensionInstalledResult = isExtensionInstalledResult;
        this.launcherVersion = launcherInfoResult?.version;
        this.lastRefresh = launcherInfoResult?.last_refresh;
      });
    this.productGuideLink = this.getProductLink();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public refreshLauncher(): void {
    this.mountService.refreshLauncher('refreshButton', this.refreshButtonState);
  }

  public isSupportedBrowser(): boolean {
    return isSupportedBrowser();
  }

  public getExtensionInstallTooltip(): string {
    if (!isSupportedBrowser()) {
      return 'This extension does not currently support this browser. Please install with a different browser.';
    } else if (this.isExtensionInstalledResult) {
      return 'This extension is already installed.';
    } else {
      return 'Click to install the extension to your browser';
    }
  }

  public openExtensionTab(): void {
    window.open('https://chrome.google.com/webstore/detail/agilicus-clickonce/ibelfgpfhoiglfahchngafbookbcmncc', '_blank');
  }

  public getLauncherInstallTooltip(): string {
    if (!!this.launcherVersion) {
      return 'A desktop integration installation is already detected. Click to reinstall.';
    }
    return 'Click to install the desktop integration';
  }

  public getRefreshLauncherTooltip(): string {
    if (this.refreshButtonState === 'pending') {
      return 'Refresh is in process now.';
    }
    if (!this.launcherVersion) {
      return 'There is no desktop integration currently installed. Please click the "INSTALL NOW" button above to install the desktop integration.';
    }
    return 'Click to refresh your existing desktop integration';
  }

  public getFormattedLastRefreshDateString(): string {
    if (!this.lastRefresh) {
      return 'Not available';
    }
    return convertDateToReadableFormat(this.lastRefresh);
  }

  public getLastRefreshTooltip(): string {
    if (!this.launcherVersion) {
      return 'There is no desktop integration currently installed. Please click the "INSTALL NOW" button above to install the desktop integration.';
    }
    if (!this.lastRefresh) {
      return 'The desktop integration has not refreshed since installed';
    }
    return 'This is the last time the desktop integration refresh was run';
  }

  public canLoadPageData(): boolean {
    return this.isExtensionInstalledResult !== undefined && this.launcherVersion !== null;
  }

  public async installAndOpenDialog() {
    const data = {
      productGuideLink: this.productGuideLink,
      issuer: await this.mountService.getIssuer(),
    };
    this.launcherInstructionsDialog.open(
      LauncherInstructionsDialogComponent,
      getDefaultDialogConfig({
        maxWidth: '1000px',
        height: '78%',
        width: '70vw',
        data,
      })
    );
  }
}
