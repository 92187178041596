<div class="dialog-container">
    <mat-dialog-content>
        <mat-tab-group mat-stretch-tabs animationDuration="0ms" [(selectedIndex)]="defaultTabIndex">
          <!-- Linux Tab -->
          <mat-tab label="Linux">
            <div class="mat-tab-content"><br>
              <div class="tasks-list-container">
                <ol class="tasks-list">
                  <span class="inline-product-guide">
                    To learn more, refer to the <a [href]="data.productGuideLink" target="_blank">Product Guide</a>.<br><br>
                  </span>
                  <!-- Step 1 -->
                  <li>
                    <app-launcher-download-button
                      (downloadFile)="downloadFile('linux')"
                    >
                    </app-launcher-download-button>
                  </li>

                  <!-- Step 2 -->
                  <li>
                    Make the downloaded file executable<br><br>
                    <div class="bullet-point">
                      Right-click the file and click <strong>Properties</strong>, click <strong>Permissions</strong>, and check the <strong>Execute</strong> box to allow it to run as program (see image)<br><br>
                    </div>
                  </li>

                  <!-- Step 3 -->
                  <li>
                    Run the file
                  </li>
                </ol>
                <div class="install-img">
                  <img class="bordered-img" src="assets/img/linux-instructions.png" height=300>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Windows Tab -->
          <mat-tab label="Windows">
            <div class="mat-tab-content"><br>
              <div class="tasks-list-container">
                <ol class="tasks-list">
                  <span class="inline-product-guide">
                    To learn more, refer to the <a [href]="data.productGuideLink" target="_blank">Product Guide</a>.<br><br>
                  </span>
                  <!-- Step 1 -->
                  <li>
                    <app-launcher-download-button
                      (downloadFile)="downloadFile('windows')"
                    >
                    </app-launcher-download-button>
                  </li>

                  <!-- Step 2 -->
                  <li>
                    Run the downloaded file<br><br>
                  </li>
                </ol>
                <div class="install-img">
                  <img class="bordered-img" src="assets/img/windows-instructions.png" height=300>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Mac Tab -->
          <mat-tab label="Mac">
            <div class="mat-tab-content"><br>
              <div class="tasks-list-container">
                <ol class="tasks-list">
                  <span class="inline-product-guide">
                    To learn more, refer to the <a [href]="data.productGuideLink" target="_blank">Product Guide</a>.<br><br>
                  </span>
                  <!-- Step 1 -->
                  <li>
                    Copy the unique install code:
                    <button
                      mat-raised-button color="primary"
                      class="copy-button"
                      matTooltip="Copy the install code"
                      (click)="onCopyToClipboard()"
                    >
                      <mat-icon>content_copy</mat-icon> COPY
                    </button>
                  </li>

                  <!-- Step 2 -->
                  <li>
                    <app-launcher-download-button
                      (downloadFile)="downloadFileToMac()"
                      [disable]="disableMacDownloadButton()"
                      disabledTooltipText="Please copy the install code before downloading"
                    >
                    </app-launcher-download-button>
                  </li>

                  <!-- Step 3 -->
                  <li>
                    Install the downloaded "agilicus-launcher" file
                  </li>

                   <!-- Step 4 -->
                   <li>
                    Paste the code when prompted
                  </li>
                </ol>
                <div class="install-img">
                  <img class="bordered-img" src="assets/img/mac_launcher_install_screenshot.png" height=300>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions class="close-button" align="end">
        <button mat-raised-button mat-dialog-close tabindex="-1">CLOSE</button>
    </mat-dialog-actions>
</div>